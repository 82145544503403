// 默认缓存期限为7天
const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7;

/**
 * 创建本地缓存对象
 * @param {string=} prefixKey -
 * @param {Object} [storage=localStorage] - sessionStorage | localStorage
 */
export const createStorage = ({ prefixKey = '', storage = localStorage } = {}) => {
  /**
   * 本地缓存类
   * @class Storage
   */
  const Storage = class {
    constructor() {
      this.storage = storage;
      this.prefixKey = prefixKey;
    }

    getKey(key) {
      return `${this.prefixKey}${key}`.toUpperCase();
    }

    /**
     * @description 设置缓存
     * @param {string} key 缓存键
     * @param {*} value 缓存值
     * @param expire
     */
    set(key, value, expire = DEFAULT_CACHE_TIME) {
      const stringData = JSON.stringify({
        value,
        expire: expire !== null ? new Date().getTime() + expire * 1000 : null,
      });
      this.storage.setItem(this.getKey(key), stringData);
    }

    /**
     * 读取缓存
     * @param {string} key 缓存键
     * @param {*=} def 默认值
     */
    get(key, def = null) {
      const item = this.storage.getItem(this.getKey(key));
      if (item) {
        try {
          const data = JSON.parse(item);
          const { value, expire } = data;
          // 在有效期内直接返回
          if (expire === null || expire >= Date.now()) {
            return value;
          }
          this.remove(this.getKey(key));
        } catch (e) {
          return def;
        }
      }
      return def;
    }

    /**
     * 从缓存删除某项
     * @param {string} key
     */
    remove(key) {
      this.storage.removeItem(this.getKey(key));
    }

    /**
     * 清空所有缓存
     * @memberOf Cache
     */
    clear() {
      this.storage.clear();
    }

    /**
     * 设置cookie
     * @param {string} name cookie 名称
     * @param {*} value cookie 值
     * @param {number=} expire 过期时间
     * @param {string=} path 存储路径
     * @param {string=} domain 存储域名
     * @param {boolean=} secure https传输
     * 如果过期时间未设置，默认关闭浏览器自动删除
     * @example
     */
    setCookie(key, value, expire = DEFAULT_CACHE_TIME, path = '/', domain, secure = true) {
      if (!key || /^(?:expires|max\\-age|path|domain|secure)$/i.test(key)) {
        return false;
      }
      let sExpires = '';
      if (expire) {
        switch (expire.constructor) {
          case Number:
            sExpires =
              expire === Infinity
                ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                : '; max-age=' + expire;
            break;
          case String:
            sExpires = '; expires=' + expire;
            break;
          case Date:
            sExpires = '; expires=' + expire.toUTCString();
            break;
        }
      }
      document.cookie =
        encodeURIComponent(this.getKey(key)) +
        '=' +
        encodeURIComponent(value) +
        sExpires +
        (domain ? '; domain=' + domain : '') +
        (path ? '; path=' + path : '') +
        (secure ? '; secure' : '');
      return true;
    }

    /**
     * 根据名字获取cookie值
     * @param name
     */
    getCookie(key) {
      return (
        decodeURIComponent(
          document.cookie.replace(
            new RegExp(
              '(?:(?:^|.*;)\\s*' +
                encodeURIComponent(this.getKey(key)).replace(/[-.+*]/g, '\\$&') +
                '\\s*\\=\\s*([^;]*).*$)|^.*$'
            ),
            '$1'
          )
        ) || null
      );
    }

    getCookieNames() {
      const allKeys = document.cookie
        .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
        .split(/\s*(?:=[^;]*)?;\s*/);
      for (let i = 0; i < allKeys.length; i++) {
        allKeys[i] = decodeURIComponent(allKeys[i]);
      }
      return allKeys;
    }

    /**
     * 根据名字删除指定的cookie
     * @param {string} key
     */
    removeCookie(key, path, domain) {
      if (!key || !this.hasCookie(key)) {
        return false;
      }
      document.cookie =
        encodeURIComponent(this.getKey(key)) +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
        (domain ? '; domain=' + domain : '') +
        (path ? '; path=' + path : '');
      return true;
    }

    hasCookie(key) {
      return new RegExp(
        '(?:^|;\\s*)' + encodeURIComponent(this.getKey(key)).replace(/[-.+*]/g, '\\$&') + '\\s*\\='
      ).test(document.cookie);
    }

    /**
     * 清空cookie，使所有cookie失效
     */
    //清除所有cookie函数
    clearCookie(path, domain) {
      const keys = this.getCookieNames();
      if (keys) {
        for (let i = keys.length; i--; ) {
          this.removeCookie(keys[i], path, domain);
        }
      }
    }
  };
  return new Storage();
};

export const storage = createStorage();

export default Storage;
