import { storage } from '@/utils/storage';
import { getSysSetting, getGameListData } from '@/api/system';

const LOCALE_KEY = 'LOCALE_INFO';
const LOCALE_LIST_KEY = 'LOCALE_LIST';
const PAYMENT_LIST_KEY = 'PAYMENT_LIST';
const COIN_LIST_KEY = 'COIN_LIST';
const GAME_LIST_KEY = 'GAME_LIST';
const OAUTH_LIST_KEY = 'OAUTH_LIST';

const localeSetting = {
  id: 0,
  cn_comment: 'English',
  code: 'en-US',
  is_default: false,
};
const localeSettingList = [];

const lsLocaleSetting = storage.get(LOCALE_KEY) || localeSetting;
const lsLocaleSettingList = storage.get(LOCALE_LIST_KEY) || localeSettingList;

const lsPaymentList = storage.get(PAYMENT_LIST_KEY) || [];
const lsCoinList = storage.get(COIN_LIST_KEY) || [];
const lsGameList = storage.get(GAME_LIST_KEY) || [];
const lsOAuthList = storage.get(OAUTH_LIST_KEY) || [];

const systemStore = {
  state: () => ({
    localeInfo: lsLocaleSetting,
    localeList: lsLocaleSettingList,
    paymentList: lsPaymentList,
    coinList: lsCoinList,
    gameList: lsGameList,
    oAuthList: lsOAuthList,
  }),
  getters: {
    defaultLocale: (state) => {
      return state.localeInfo || localeSetting;
    },
    localeOptions: (state) => {
      return state.localeList.map((item) => ({
        text: item.cn_comment,
        value: item.id,
        ...item,
      }));
    },
    coinOptions: (state) => {
      return state.coinList.map((item) => ({
        text: item.code,
        value: item.id,
      }));
    },
    gameOptions: (state) => {
      return state.gameList.map((item) => ({
        text: item.app_name,
        value: item.app_uid,
      }));
    },
    defaultGame: (state) => {
      return state.gameList[0];
    },
  },
  actions: {
    setLocaleInfo(context, info) {
      context.commit('SET_LOCALE_INFO', {
        localeInfo: info,
      });
    },
    initLocale(context) {
      context.commit('SET_LOCALE_INFO', {
        localeInfo: localeSetting,
      });
    },
    async getSysSetting(context) {
      try {
        const res = await getSysSetting();
        const { languages, pay_way, price_type, third_login } = res;
        context.commit('SET_SYSTEM_INFO', {
          localeList: languages,
          paymentList: pay_way,
          coinList: price_type,
          oAuthList: third_login,
        });

        storage.set(LOCALE_LIST_KEY, languages);
        storage.set(PAYMENT_LIST_KEY, pay_way);
        storage.set(COIN_LIST_KEY, price_type);
        storage.set(OAUTH_LIST_KEY, third_login);
      } catch (error) {
        return error;
      }
    },
    async getGameList(context) {
      try {
        const res = await getGameListData();
        const gameListComputed = res
          .filter((data) => data.status === 1)
          .map((item) =>
            Object.assign(item, {
              label: item.app_name,
              value: item.app_uid,
            })
          );
        context.commit('SET_GAME_INFO', {
          gameList: gameListComputed,
        });
        storage.set(GAME_LIST_KEY, gameListComputed);
        return gameListComputed;
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    SET_LOCALE_INFO(state, payload) {
      const { localeInfo } = payload;
      state.localeInfo = { ...state.localeInfo, ...localeInfo };
      storage.set(LOCALE_KEY, state.localeInfo);
    },
    SET_SYSTEM_INFO(state, payload) {
      const { localeList, paymentList, coinList } = payload;
      state.localeList = localeList;
      state.paymentList = paymentList;
      state.coinList = coinList;
    },
    SET_GAME_INFO(state, payload) {
      const { gameList } = payload;
      state.gameList = gameList;
    },
  },
};

export default systemStore;
