import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locale } from 'vant';

import { storage } from '@/utils/storage';

Vue.use(VueI18n);

import { setHtmlPageLang, setLoadLocalePool } from './helper';

async function createI18nOptions() {
  const locale = storage.get('LOCALE_INFO') ? storage.get('LOCALE_INFO').code : 'en-US';
  const defaultLocale = await import(`./lang/${locale}.js`);
  const libLocale = await import(`./lib/${locale}.js`);

  const message = defaultLocale.default.message || {};
  Locale.use(locale, libLocale.default);
  setHtmlPageLang(locale);
  setLoadLocalePool((loadLocalePool) => {
    loadLocalePool.push(locale);
  });

  return {
    locale,
    fallbackLocale: 'en-US',
    messages: {
      [locale]: message,
    },
    // availableLocales: availableLocales,
    sync: true, //If you don’t want to inherit locale from global scope, you need to set sync of i18n component option to false.
    silentTranslationWarn: true, // true - warning off
    silentFallbackWarn: true,
  };
}

export let i18n;
// setup i18n instance with glob
export async function setupI18n() {
  const options = await createI18nOptions();
  i18n = new VueI18n(options);
  return i18n;
}
