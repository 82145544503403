import http from '@/utils/axios';

export const sendEmail = (email) =>
  http.request({
    url: '/user/push_validate_code',
    method: 'post',
    data: { email },
  });

/**
 * @param {*} data { email, password, register_way, var_code, policy }
 */
export const userRegister = (data) =>
  http.request({
    url: '/user/register',
    method: 'post',
    data,
  });

/**
 * @param {*} data { email, password, login_way }
 */
export const userLogin = (data) =>
  http.request({
    url: '/user/login',
    method: 'post',
    data,
  });

export const authLogin = (data) =>
  http.request({
    url: '/user/third_login',
    method: 'post',
    data,
  });

export const userValidate = () =>
  http.request(
    {
      url: '/user/auth/validate',
      method: 'post',
      data: {},
    },
    { isOnlyReturnCode: true, isShowErrorMessage: false }
  );

export const userLogout = () =>
  http.request({
    url: '/user/auth/login_out',
    method: 'post',
    data: {},
  });

export const findPassword = (email) =>
  http.request({
    url: '/user/find_password',
    method: 'post',
    data: { email },
  });

export const resetPassword = (token, password) =>
  http.request({
    headers: {
      'user-token': token,
    },
    url: '/user/auth/reset_password',
    method: 'post',
    data: { password },
  });

/**
 * @param {*} data { old_password, password }
 */
export const changePassword = (data) =>
  http.request({
    url: '/user/auth/update_password',
    method: 'post',
    data,
  });
