<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'App',
    methods: {
      ...mapActions({
        getSysSetting: 'getSysSetting',
        getGameList: 'getGameList',
      }),
    },
    created() {
      this.getSysSetting();
      this.getGameList();
    },
  };
</script>

<style lang="less">
  #app {
    height: 100vh;
    font-size: 14px;
    line-height: 1;
  }
  .page-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #191b1f;
  }
  .page-header {
    height: 60px;
  }
  .page-body {
    height: calc(~'100vh - 60px');
    flex: 1;
    overflow-y: auto;
  }
</style>
