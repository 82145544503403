// https://developers.facebook.com/docs/apps/versions/
// https://developers.facebook.com/docs/javascript/quickstart/
// https://developers.facebook.com/docs/javascript/reference/
// https://developers.facebook.com/docs/javascript/reference/FB.init/
// https://stackoverflow.com/questions/43445301/access-token-warning-when-logging-in-using-facebook-javascript-sdk

// https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus
const LOGIN_STATUSES = {
  UNKNOWN: 'unknown',
  CONNECTED: 'connected',
  NOT_AUTHORIZED: 'not_authorized',
};

function handleLoadError(error) {
  // eslint-disable-next-line no-console
  console.error(new URIError(`the script ${error.target.src} didn't load correctly.`));
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLScriptElement
function initSdk(options, locale = 'en_US') {
  return new Promise((resolve, reject) => {
    // prettier-ignore
    // https://developers.facebook.com/docs/javascript/advanced-setup
    window.fbAsyncInit = function() {
      window.FB.init(options)
      resolve(window.FB)
    };
    /* eslint-disable */
    // prettier-ignore
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s); js.id = id;
      js.src = `//connect.facebook.net/${locale}/sdk.js`;
      js.onerror = error => { handleLoadError(error); reject(error) }; // non-facebook line
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook_auth2'));
    /* eslint-enable */
    window.setTimeout(
      () =>
        reject('window.fbAsyncInit timed out, see: https://developers.facebook.com/support/bugs/'),
      3000
    );
  });
}

function removeScript() {
  return new Promise((resolve) => {
    const script = document.getElementById('facebook_auth2');
    if (script) script.remove();
    window.setTimeout(resolve);
  });
}

export default class FacebookAuth {
  constructor(isExternal = false, pending = null, consumers = 0) {
    this.isExternal = isExternal;
    this.pending = pending;
    this.consumers = consumers;
  }

  static async _init(options, locale) {
    if (this.isExternal) return window.FB;
    if (window.FB) {
      this.isExternal = true;
      return window.FB;
    }
    if (this.pending) return this.pending;
    this.pending = initSdk(options, locale);
    return this.pending;
  }

  static reset() {
    this.consumers = 0;
    this.pending = null;
    this.isExternal = false;
  }

  static subscribe(options, locale) {
    this.consumers++;
    return this._init(options, locale);
  }

  static async unsubscribe() {
    this.consumers--;
    if (this.isExternal || this.consumers) return;
    this.reset();
    return removeScript();
  }

  static isConnected(status) {
    return status === LOGIN_STATUSES.CONNECTED;
  }

  static isDisconnected(status) {
    return !this.isConnected(status);
  }

  static getLoginStatus() {
    return new Promise((resolve) => window.FB.getLoginStatus(resolve));
  }

  static login(options) {
    return new Promise((resolve) => window.FB.login(resolve, options));
  }

  static logout() {
    return new Promise((resolve) => window.FB.logout(resolve));
  }
}
