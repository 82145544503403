import Vue from 'vue';
import {
  Button,
  Calendar,
  Cell,
  CellGroup,
  Checkbox,
  Empty,
  Form,
  Field,
  Icon,
  Lazyload,
  List,
  Notify,
  Dialog,
  NavBar,
  DropdownMenu,
  DropdownItem,
  Picker,
  Popup,
  PullRefresh,
  RadioGroup,
  Radio,
  Toast,
} from 'vant';
import { Image as VanImage } from 'vant';

Toast.setDefaultOptions({ position: 'top' });

Vue.use(Button);
Vue.use(Calendar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(Empty);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Lazyload);
Vue.use(List);
Vue.use(Notify);
Vue.use(Dialog);
Vue.use(NavBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(PullRefresh);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Toast);
Vue.use(VanImage);

window.$dialog = Dialog;
window.$notify = Notify;
window.$toast = Toast;

import Header from './components/Header.vue';
Vue.component('ace-header', Header);
