import Vue from 'vue';
import Vuex from 'vuex';
import systemStore from './modules/system';
import userStore from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    system: systemStore,
    user: userStore,
  },
});
