import { Locale } from 'vant';
import { i18n } from './setupI18n';
import { loadLocalePool, setHtmlPageLang } from './helper';

function setI18nLanguage(locale) {
  setHtmlPageLang(locale);
}

export async function changeLocale(locale) {
  const currentLocale = i18n.locale;
  if (currentLocale === locale) {
    return locale;
  }

  if (loadLocalePool.includes(locale)) {
    setI18nLanguage(locale);
    return locale;
  }
  const langModule = await import(`./lang/${locale}.js`).default;
  const libModule = (await import(`./lib/${locale}.js`)).default;
  if (!langModule || !libModule) return;

  Locale.use(locale, libModule);
  const { message } = langModule;
  i18n.setLocaleMessage(locale, message);
  loadLocalePool.push(locale);

  setI18nLanguage(locale);
  return locale;
}
