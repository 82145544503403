import { storage } from '@/utils/storage';

const userStore = {
  state: () => ({
    userEmail: storage.getCookie('EMAIL') || '',
    userToken: storage.getCookie('TOKEN') || '',
    oAuthedPlatform: storage.get('O_AUTHED_PLATFORM') || '',
  }),
  getters: {
    isLogined: (state) => {
      return Boolean(state.userEmail && state.userToken);
    },
  },
  actions: {
    setUserInfo(context, data) {
      const { userEmail, userToken, oAuthedPlatform = '' } = data;
      context.commit('SET_USER_INFO', {
        userEmail,
        userToken,
        oAuthedPlatform,
      });
      if (userEmail && userToken) {
        storage.setCookie('TOKEN', userToken, Infinity, '/', process.env.VUE_APP_COOKIE_DOMAIN);
        storage.setCookie('EMAIL', userEmail, Infinity, '/', process.env.VUE_APP_COOKIE_DOMAIN);
      } else {
        storage.clearCookie('/', process.env.VUE_APP_COOKIE_DOMAIN);
      }
      storage.set('O_AUTHED_PLATFORM', oAuthedPlatform);
    },
  },
  mutations: {
    SET_USER_INFO(state, payload) {
      const { userEmail, userToken, oAuthedPlatform = '' } = payload;
      state.userEmail = userEmail;
      state.userToken = userToken;
      state.oAuthedPlatform = oAuthedPlatform;
    },
  },
};

export default userStore;
