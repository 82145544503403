import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setupI18n } from './locales/setupI18n';
import { getParam, loadDevTool } from './utils/assist';
import { storage } from './utils/storage';
import { formatToDateTime } from './utils/dateFormat';
import { isMobile } from './utils/is';

import './components';
import 'windi.css';
import './main.css';

Vue.config.productionTip = false;

async function bootstrap() {
  const i18n = await setupI18n();

  router.beforeEach((to, from, next) => {
    const token = storage.getCookie('TOKEN');
    if (to.meta.requiresAuth && !token) {
      storage.clearCookie('/', process.env.VUE_APP_COOKIE_DOMAIN);
      window.$notify({ type: 'danger', message: i18n.tc('system.api.timeoutMessage') });
      next({ name: 'login' });
      return;
    }
    if (to.meta.title) {
      document.title = i18n.tc(`system.${to.meta.title}`) + ' - ' + i18n.tc('system.app.title');
    }
    if (to.meta.description) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', i18n.tc(`system.${to.meta.description}`));
    }
    next();
  });

  const vm = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  });

  vm.$mount('#app');

  window.lastBuildTime = formatToDateTime(Date.now());

  if (getParam('reset_token')) {
    router.replace({ name: 'find-password-form', query: { reset_token: getParam('reset_token') } });
  }

  if (getParam('clear')) {
    storage.clear();
  }

  if (process.env.VUE_APP_BUILD_MODE === 'testing') {
    loadDevTool();
  }
}

if (!isMobile(window.navigator.userAgent)) {
  let redirectUrl = process.env.VUE_APP_PC_URL;
  const token = getParam('reset_token');
  if (token) {
    redirectUrl = `${redirectUrl}?reset_token=${token}`;
  }
  if (window.location.pathname === '/result') {
    redirectUrl = `${redirectUrl}/result${window.location.search}`;
  }
  window.location.href = redirectUrl;
} else {
  bootstrap();
}
