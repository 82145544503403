import http from '@/utils/axios';

export const getSysSetting = () =>
  http.request({
    url: '/system/list',
    method: 'post',
  });

export const getGameListData = () =>
  http.request({
    url: '/app/list',
    method: 'post',
  });

/**
 * @param {Object} data { app_uid, price_type_id }
 */
export const getMerchandise = (data) =>
  http.request({
    url: '/commodity/list',
    method: 'post',
    data,
  });

/**
 * @param {Object} data { app_uid, commodity_uid, currency_unit_id }
 */
export const getPayment = (data) =>
  http.request({
    url: '/pay/pay_way_list',
    method: 'post',
    data,
  });

/**
 * @param {Object} data { app_uid, commodity_uid, app_user_id, currency_unit_id }
 */
export const getRoleLimit = (data) =>
  http.request({
    url: '/pay/app_user_buy_verification',
    method: 'post',
    data,
  });

export const getRoleList = (app_uid) =>
  http.request({
    url: '/auth/list_app_user',
    method: 'post',
    data: {
      app_uid,
    },
  });

export const getServerList = (app_uid) =>
  http.request({
    url: '/auth/search_app_server_list',
    method: 'post',
    data: {
      app_uid,
    },
  });

/**
 * @param {Object} data { app_uid, server_id, player_id }
 */
export const getRole = (data) =>
  http.request({
    url: '/auth/search_app_user',
    method: 'post',
    data,
  });

/**
 * @param {Object} data { app_uid, server_id, player_id, nick_name, level }
 */
export const addRole = (data) =>
  http.request({
    url: '/auth/save_app_user',
    method: 'post',
    data,
  });

export const updateRole = (app_uid, id) =>
  http.request({
    url: '/auth/update_app_user',
    method: 'post',
    data: {
      app_uid,
      id,
    },
  });

export const deleteRole = (app_uid, id) =>
  http.request({
    url: '/auth/del_app_user',
    method: 'post',
    data: {
      app_uid,
      id,
    },
  });

/**
 * @param {Object} data { app_uid, commodity_uid, app_user_id, currency_unit_id, pay_way_id, os_type }
 */
export const getPay = (data) =>
  http.request({
    url: '/pay/open_order',
    method: 'post',
    data,
  });

export const getPayV2 = (data) =>
  http.request({
    url: '/pay/open_order_v2',
    method: 'post',
    data,
  });

export const getPaypalResult = (order_token) =>
  http.request(
    {
      url: '/paypal/capture',
      method: 'get',
      params: { order_token },
    },
    { isShowErrorMessage: false }
  );
/**
 * @param {Object} data { app_uid, start_time, end_time, page, page_size }
 */
export const getOrderList = (data) =>
  http.request({
    url: '/pay/order_list',
    method: 'post',
    data,
  });

export const getOrderDetail = (order_sn) =>
  http.request({
    url: '/pay/order_info',
    method: 'post',
    data: { order_sn },
  });
