export function checkStatus(status, msg, Notify, i18n) {
  switch (status) {
    case 400:
      Notify({ type: 'danger', message: `${msg}` });
      break;
    // 401: 未登录
    // 未登录则跳转登录页面，并携带当前页面的路径
    // 在登录成功后返回当前页面，这一步需要在登录页操作。
    case 401:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg401') });
      break;
    case 403:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg403') });
      break;
    // 404请求不存在
    case 404:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg404') });
      break;
    case 405:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg405') });
      break;
    case 408:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg408') });
      break;
    case 500:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg500') });
      break;
    case 501:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg501') });
      break;
    case 502:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg502') });
      break;
    case 503:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg503') });
      break;
    case 504:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg504') });
      break;
    case 505:
      Notify({ type: 'danger', message: i18n.tc('system.api.errMsg505') });
      break;
    default:
      Notify({ type: 'danger', message: msg });
  }
}
