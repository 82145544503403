import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'app.title',
      description: 'app.description',
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      title: 'app.helpCenter',
      description: 'app.description',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'login.loginButton',
      description: 'app.description',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      title: 'login.registerButton',
      description: 'app.description',
    },
  },
  {
    path: '/privacy/en-US',
    name: 'privacy-en-US',
    component: () => import(/* webpackChunkName: "privacy-en-US" */ '../views/privacy/en-US.vue'),
    meta: {
      title: 'login.policy',
      description: 'app.description',
    },
  },
  {
    path: '/privacy/zh-CN',
    name: 'privacy-zh-CN',
    component: () => import(/* webpackChunkName: "privacy-zh-CN" */ '../views/privacy/zh-CN.vue'),
    meta: {
      title: 'login.policy',
      description: 'app.description',
    },
  },
  {
    path: '/privacy/zh-TW',
    name: 'privacy-zh-TW',
    component: () => import(/* webpackChunkName: "privacy-zh-TW" */ '../views/privacy/zh-TW.vue'),
    meta: {
      title: 'login.policy',
      description: 'app.description',
    },
  },
  {
    path: '/privacy/ko-KR',
    name: 'privacy-ko-KR',
    component: () => import(/* webpackChunkName: "privacy-ko-KR" */ '../views/privacy/ko-KR.vue'),
    meta: {
      title: 'login.policy',
      description: 'app.description',
    },
  },
  {
    path: '/privacy/vi-VN',
    name: 'privacy-vi-VN',
    component: () => import(/* webpackChunkName: "privacy-vi-VN" */ '../views/privacy/vi-VN.vue'),
    meta: {
      title: 'login.policy',
      description: 'app.description',
    },
  },
  {
    path: '/find-password-email',
    name: 'find-password-email',
    component: () =>
      import(/* webpackChunkName: "find-password-email" */ '../views/FindPwdEmail.vue'),
    meta: {
      title: 'login.findPassword',
      description: 'app.description',
    },
  },
  {
    path: '/find-password-form',
    name: 'find-password-form',
    component: () =>
      import(/* webpackChunkName: "find-password-form" */ '../views/FindPwdForm.vue'),
    meta: {
      title: 'login.findPassword',
      description: 'app.description',
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '../views/ChangePwd.vue'),
    meta: {
      requiresAuth: true,
      title: 'app.changePwd',
      description: 'app.description',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
    meta: {
      title: 'charge.title',
      description: 'app.description',
    },
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/Order.vue'),
    meta: {
      requiresAuth: true,
      title: 'charge.buyNow',
      description: 'order.description',
    },
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "result" */ '../views/Result.vue'),
    meta: {
      requiresAuth: true,
      title: 'result.title',
      description: 'result.description',
    },
  },
  {
    path: '/order-list',
    name: 'order-list',
    component: () => import(/* webpackChunkName: "order-list" */ '../views/OrderList.vue'),
    meta: {
      requiresAuth: true,
      title: 'orders.title',
      description: 'orders.description',
    },
  },
  {
    path: '/order-detail',
    name: 'order-detail',
    component: () => import(/* webpackChunkName: "order-detail" */ '../views/OrderDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'order.title',
      description: 'order.description',
    },
  },
  {
    path: '/role-list',
    name: 'role-list',
    component: () => import(/* webpackChunkName: "role-list" */ '../views/RoleList.vue'),
    meta: {
      requiresAuth: true,
      title: 'charge.roleInfo',
      description: 'app.description',
    },
  },
  {
    path: '/role-add',
    name: 'role-add',
    component: () => import(/* webpackChunkName: "role-add" */ '../views/RoleAdd.vue'),
    meta: {
      requiresAuth: true,
      title: 'charge.roleInfo',
      description: 'app.description',
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
