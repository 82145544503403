// axios配置  可自行根据项目进行更改，只需更改该文件即可，其他文件可以不动
import { storage } from '@/utils/storage';
import { i18n } from '@/locales/setupI18n';
import router from '@/router';
import store from '@/store';

import { VAxios } from './Axios';
import axios from 'axios';
import { checkStatus } from './checkStatus';
import { joinTimestamp, formatRequestDate } from './helper';

import { isString } from '../is';
import { setObjToUrlParams } from '../assist';

const urlPrefix = process.env.VUE_APP_API_PREFIX || '';

function clearToken() {
  storage.clearCookie('/', process.env.VUE_APP_COOKIE_DOMAIN);
  store.dispatch('setUserInfo', {
    userEmail: '',
    userToken: '',
  });
  if (router.currentRoute.meta.requiresAuth) {
    router.replace({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
  }
}

/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform = {
  /**
   * @description: 处理请求数据
   */
  transformRequestData: (response, options) => {
    // @ts-ignore
    const { $notify: Notify } = window;
    const {
      isTransformResponse,
      isReturnNativeResponse,
      isShowErrorMessage = true,
      isOnlyReturnCode = false,
    } = options;

    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    if (isReturnNativeResponse) {
      return response;
    }
    if (!response.data) {
      // return '[HTTP] Request has no return value';
      throw new Error(i18n.tc('system.api.apiRequestFailed'));
    }
    // 不进行任何处理，直接返回
    // 用于页面代码可能需要直接获取code，data，message这些信息时开启
    if (!isTransformResponse) {
      return response.data;
    }

    //  这里 code，data，message为 后台统一的字段
    const { code, data, message } = response.data;

    if (isOnlyReturnCode) {
      if (code === 401 || code === 1002) {
        clearToken();
      }
      if (code !== 1 && isShowErrorMessage && message) {
        Notify({ type: 'danger', message: message });
      }
      return code;
    }
    // 接口请求成功，直接返回结果
    if (code === 1) {
      return data || true;
    } else if (code === 401) {
      clearToken();
      if (isShowErrorMessage) {
        Notify({ type: 'danger', message: i18n.tc('system.api.timeoutMessage') });
      }
    } else {
      if (code === 1002) {
        clearToken();
      }
      if (isShowErrorMessage && message) {
        Notify({ type: 'danger', message: message });
      }
      return false;
    }
  },

  // 请求之前处理config
  beforeRequestHook: (config, options) => {
    const { apiUrl, joinPrefix, joinParamsToUrl, formatDate, joinTime = true } = options;

    if (joinPrefix) {
      config.url = `${urlPrefix}${config.url}`;
    }

    if (apiUrl && isString(apiUrl)) {
      config.url = `${apiUrl}${config.url}`;
    }
    const params = config.params || {};
    const data = config.data || false;
    if (config.method.toUpperCase() === 'GET') {
      if (!isString(params)) {
        // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
        config.params = Object.assign(params || {}, joinTimestamp(joinTime, false));
      } else {
        // 兼容restful风格
        config.url = config.url + params + `${joinTimestamp(joinTime, true)}`;
        config.params = undefined;
      }
    } else {
      if (!isString(params)) {
        formatDate && formatRequestDate(params);
        if (config.data && Object.keys(config.data).length) {
          config.data = data;
          config.params = params;
        } else {
          config.data = params;
          config.params = undefined;
        }
        if (joinParamsToUrl) {
          config.url = setObjToUrlParams(config.url, Object.assign({}, config.params, config.data));
        }
      } else {
        // 兼容restful风格
        config.url = config.url + params;
        config.params = undefined;
      }
    }
    return config;
  },

  /**
   * @description: 请求拦截器处理
   */
  requestInterceptors: (config) => {
    // 请求之前处理config
    config.headers['http-token'] = process.env.VUE_APP_HTTP_TOKEN;
    const { code = 'en-US' } = storage.get('LOCALE_INFO') || {};
    config.headers['lan-code'] = code;
    const token = storage.getCookie('TOKEN');
    if (token) {
      config.headers['user-token'] = token;
    }
    return config;
  },

  /**
   * @description: 响应错误处理
   */
  responseInterceptorsCatch: (error) => {
    // @ts-ignore
    const { $notify: Notify, $dialog: Dialog } = window;
    const { code, message } = error || {};
    // TODO 此处要根据后端接口返回格式修改
    const err = error.toString() || '';

    try {
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        Notify({ type: 'danger', message: i18n.tc('system.api.apiTimeoutMessage') });
        return;
      }
      if (err && err.includes('Network Error')) {
        Dialog &&
          Dialog.alert({
            title: i18n.tc('system.api.errorTip'),
            message: i18n.tc('system.api.networkExceptionMsg'),
          });
        return;
      }
    } catch (error) {
      throw new Error(error);
    }
    // 请求是否被取消
    const isCancel = axios.isCancel(error);
    if (!isCancel) {
      if (error.response) {
        checkStatus(error.response.status, message, Notify, i18n);
      }
    } else {
      console.warn(error, '请求被取消！');
    }
    return error.message;
  },
};

const Axios = new VAxios({
  timeout: 30 * 1000,
  // 接口前缀
  prefixUrl: urlPrefix,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  // 数据处理方式
  transform,
  // 配置项，下面的选项都可以在独立的接口请求中覆盖
  requestOptions: {
    // 默认将prefix 添加到url
    joinPrefix: true,
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    isReturnNativeResponse: false,
    // 需要对返回数据进行处理
    isTransformResponse: true,
    // post请求的时候添加参数到url
    joinParamsToUrl: false,
    // 格式化提交参数时间
    formatDate: true,
    // 消息提示类型
    errorMessageMode: 'message',
    // 接口地址
    apiUrl: process.env.VUE_APP_API_URL,
  },
  withCredentials: false,
});

export default Axios;
