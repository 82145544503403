<template>
  <div id="header" class="header">
    <van-nav-bar>
      <template v-if="showBack" #left>
        <img src="../assets/arrow_back.png" alt="back" class="header-left" @click="onBack" />
      </template>
      <template #title>
        <div class="header-title">
          <slot></slot>
        </div>
      </template>
      <template #right>
        <div v-if="showAction" class="header-right" @click="showActionPicker = true">
          <img src="../assets/menu.png" alt="menu" class="menu" />
        </div>
        <slot name="right"></slot>
      </template>
    </van-nav-bar>
    <van-popup v-model="showActionPicker" position="right" class="w-200px h-full">
      <div class="relative pt-20px h-full overflow-y-auto">
        <div
          class="mx-auto w-60px h-60px text-secondary rounded-1/2 text-center leading-60px text-4xl"
          :class="isLogined ? 'bg-secondary' : ''"
        >
          <img
            v-if="!isLogined"
            class="inline-block w-full h-full"
            src="../assets/logo.png"
            alt="logo"
          />
          <span v-if="isLogined">{{ userAvatar }}</span>
        </div>
        <template v-if="isLogined">
          <div class="py-12px px-20px h-40px text-center van-ellipsis">{{ userEmail }}</div>
          <div
            v-if="oAuthedPlatform === ''"
            class="py-12px px-20px border-top-1 text-secondary"
            @click="onMenuSelect('password')"
          >
            {{ $t('system.app.changePwd') }}
          </div>
          <div class="py-12px px-20px border-top-1 text-secondary" @click="onMenuSelect('orders')">
            {{ $t('system.app.chargeRecord') }}
          </div>
        </template>
        <div class="py-12px px-20px border-y-1 text-secondary" @click="onMenuSelect('help')">
          {{ $t('system.app.helpCenter') }}
        </div>
        <van-dropdown-menu :overlay="false" class="header-lang">
          <van-dropdown-item
            ref="headerLang"
            v-model="selectedLangId"
            :options="localeOptions"
            @change="onLangChange"
          >
            <template #title>
              <div class="text-secondary">{{ selectedLangName }}</div>
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
        <div class="px-16px absolute bottom-30px w-full">
          <van-button
            v-if="isLogined"
            block
            type="danger"
            :text="$t('system.login.logoutButton')"
            @click="onMenuSelect('logout')"
          />
          <van-button
            v-if="!isLogined"
            block
            type="primary"
            :text="$t('system.login.loginButton')"
            @click="onMenuSelect('login')"
          />
          <van-button
            v-if="!isLogined"
            block
            class="mt-20px"
            :text="$t('system.login.freeAccount')"
            @click="onMenuSelect('register')"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { changeLocale } from '@/locales/useLocale';
  import { userLogout } from '@/api/user';
  import FacebookAuth from './OAuth/FacebookAuth';
  import GoogleAuth from './OAuth/GoogleAuth';

  export default {
    name: 'Header',
    props: {
      showBack: {
        type: Boolean,
        default: false,
      },
      backFunc: {
        type: Function,
      },
      showAction: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showActionPicker: false,
        selectedLangId: 0,
        selectedLangName: '',
      };
    },
    computed: {
      ...mapGetters({
        isLogined: 'isLogined',
        localeOptions: 'localeOptions',
        defaultLocale: 'defaultLocale',
      }),
      ...mapState({
        userEmail: (state) => state.user.userEmail,
        oAuthedPlatform: (state) => state.user.oAuthedPlatform,
      }),
      userAvatar() {
        return this.userEmail.substr(0, 1);
      },
    },
    methods: {
      ...mapActions({
        setLocaleInfo: 'setLocaleInfo',
        setUserInfo: 'setUserInfo',
      }),
      onBack() {
        if (typeof this.backFunc === 'function') {
          this.backFunc();
        } else {
          this.$router.go(-1);
        }
      },
      onMenuSelect(value) {
        switch (value) {
          case 'login':
            this.$router.push({ name: 'login' });
            break;
          case 'register':
            this.$router.push({ name: 'register' });
            break;
          case 'password':
            this.$router.push({ name: 'change-password' });
            break;
          case 'orders':
            this.$router.push({ name: 'order-list' });
            break;
          case 'help':
            this.$router.push({ name: 'help' });
            break;
          case 'logout':
            this.logOut();
            break;
          default:
            break;
        }
      },
      async onLangChange(value) {
        const selectedLocale = await this.localeOptions.find((item) => item.value === value);
        await changeLocale(selectedLocale.code);
        this.setLocaleInfo(selectedLocale);
        window.location.reload();
      },
      async logOut() {
        this.$toast.loading({
          message: this.$t('common.loadingText'),
          duration: 0,
        });
        try {
          if (this.oAuthedPlatform === 'facebook') {
            await FacebookAuth.subscribe(
              {
                appId: process.env.VUE_APP_FACEBOOK_ID,
                version: 'v9.0',
                cookie: true,
                xfbml: true,
                autoLogAppEvents: true,
              },
              this.$i18n.locale
            );
            const fbLoginState = await FacebookAuth.getLoginStatus();
            if (fbLoginState && fbLoginState.status === 'connected') {
              await FacebookAuth.logout();
            }
          }
          if (this.oAuthedPlatform === 'google') {
            await GoogleAuth.load({
              client_id: process.env.VUE_APP_GOOGLE_ID,
            });
            const ggLoginState = await GoogleAuth.isSignedIn();
            if (ggLoginState) {
              await GoogleAuth.signOut();
            }
          }
          const res = await userLogout();
          if (res) {
            this.$notify({
              type: 'success',
              message: this.$t('system.login.logoutSucceed'),
            });
            this.setUserInfo({
              userEmail: '',
              userToken: '',
            });
            if (this.$route.meta.requiresAuth) {
              this.$router.replace('/');
            }
          }
        } finally {
          this.$toast.clear();
          this.showActionPicker = false;
        }
      },
    },
    mounted() {
      this.selectedLangId = this.defaultLocale.id;
      this.selectedLangName = this.defaultLocale.cn_comment;
    },
    beforeDestroy() {
      this.$toast.clear();
    },
  };
</script>

<style lang="less">
  .header {
    &-left {
      width: 20px;
      height: 20px;
    }
    &-title {
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 16px;
      .logo {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }
    }
    &-right {
      &::after {
        display: none;
      }
      .menu {
        width: 20px;
        height: 20px;
      }
    }
    &-lang {
      .van-dropdown-menu__item {
        justify-content: flex-start;
      }
      .van-dropdown-menu__title {
        width: 174px;
        padding-left: 20px;
      }
      .van-dropdown-item--down {
        bottom: unset;
        min-height: 250px;
      }
      .van-dropdown-item__content {
        padding-left: 4px;
        padding-right: 4px;
        max-height: 100%;
      }
    }
  }
</style>
